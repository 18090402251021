import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRegionsList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refRegionsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false },
    { key: 'is_add_to_cart', label: 'Add to cart', sortable: false },
    { key: 'isMaintance', label: 'maintenance', sortable: false },
    { key: 'isPayment', label: 'Payment', sortable: false },
    { key: 'showPriceLow', sortable: false },
    // { key: 'taxService(%)', sortable: false },
    { key: 'taxLocal(%)',label: 'Tax(%)' , sortable: false },
    // { key: 'taxIndirect(%)', sortable: false },
    { key: 'currency', sortable: false },
    { key: 'regionOwner', sortable: false },
    { key: 'action' },
  ]
  const perPage = ref(10)
  const totalRegionConfig = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const outOfStockFilter = ref(null)
  const activeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refRegionsTable.value
      ? refRegionsTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRegionConfig.value,
    }
  })

  const refetchData = () => {
    refRegionsTable.value.refresh()
  }
  const fetchRegions = (ctx, callback) => {
    store
      .dispatch('regionConfig/fetchRegionConfig', {
        limit: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const regionConfigs = response.body.data
        totalRegionConfig.value = response.body.total
        callback(regionConfigs)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching regions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      showTable.value = true
           clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchRegions,
    tableColumns,
    perPage,
    currentPage,
    totalRegionConfig,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRegionsTable,
    refetchData,
    // Extra
  }
}
