 <template>
  <b-sidebar
    id="update-region-sidebar"
    :visible="isUpdateRegionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-region-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Update Region Config</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="dataRegion.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Upskill"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group label="Address" label-for="Address">
              <b-form-input
                id="address"
                v-model="dataRegion.address"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Address"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- PostCode -->
          <validation-provider
            #default="validationContext"
            name="Post code"
            rules="required"
          >
            <b-form-group label="Post code" label-for="Post code">
              <b-form-input
                id="post_code"
                v-model="dataRegion.post_code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="50706 Kuala Lumpur"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
            <b-form-group label="Country" label-for="Country">
              <b-form-input
                id="country"
                v-model="dataRegion.country"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Malaysia"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Is Maintance -->
          <validation-provider
            #default="validationContext"
            name="isMaintance"
            rules="required"
          >
            <b-form-group
              label="Maintaince"
              label-for="isMaintance"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="dataRegion.is_maintaince"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="isMaintanceOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="isMaintance"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Add to cart -->
          <validation-provider
            #default="validationContext"
            name="addToCart"
            rules="required"
          >
            <b-form-group
              label="Add to cart"
              label-for="addToCart"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="dataRegion.add_to_cart"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="isMaintanceOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="isMaintance"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Payment -->
          <validation-provider
            #default="validationContext"
            name="payment"
            rules="required"
          >
            <b-form-group
              label="Payment"
              label-for="payment"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="dataRegion.is_payment"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="isMaintanceOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="isMaintance"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Tax indirect -->
          <!-- <validation-provider
            #default="validationContext"
            name="Tax indirect"
            rules="required"
          >
            <b-form-group
              label="Tax indirect(%)"
              label-for="Tax indirect"
            >
              <b-form-input
                id="Tax indirect"
                v-model="dataRegion.tax_indirect"
                step="0.01"
                min="0.00"
                max="100.00"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Upskill"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <!-- Tax local -->
          <validation-provider
            #default="validationContext"
            name="Tax"
            rules="required"
          >
            <b-form-group label="Tax (%)" label-for="Tax">
              <b-form-input
                id="Tax"
                v-model="dataRegion.tax_local"
                step="0.01"
                min="0.00"
                max="100.00"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="100"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Tax service -->
          <!-- <validation-provider
            #default="validationContext"
            name="Tax service"
            rules="required"
          >
            <b-form-group
              label="Tax service(%)"
              label-for="Tax service"
            >
              <b-form-input
                id="Tax service"
                v-model="dataRegion.tax_service"
                step="0.01"
                min="0.00"
                max="100.00"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Upskill"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <!-- Currency -->
          <validation-provider
            #default="validationContext"
            name="Currency"
            rules="required"
          >
            <b-form-group label="Currency" label-for="currency">
              <b-form-input
                id="currency"
                v-model="dataRegion.currency"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="MYR"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Region_url -->
          <validation-provider
            #default="validationContext"
            name="Region url"
            rules="required"
          >
            <b-form-group label="Region url" label-for="region_url">
              <b-form-input
                id="region_url"
                v-model="dataRegion.region_url"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="https://marketplace.my.pwc.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- GTM id -->
          <b-form-group label="GTM Id" label-for="gtm_id">
            <b-form-input
              id="gtm_id"
              v-model="dataRegion.gtm_id"
              autofocus
              trim
              placeholder="gtm_id"
            />
          </b-form-group>
          <!-- Facebook pixel id -->
          <b-form-group label="Facebook pixel id" label-for="facebook_pixel_id">
            <b-form-input
              id="facebook_pixel_id"
              v-model="dataRegion.facebook_pixel_id"
              autofocus
              trim
              placeholder="facebook_pixel_id"
            />
          </b-form-group>
          <!-- Gtag id -->
          <b-form-group label="Gtag id" label-for="gtag_id">
            <b-form-input
              id="gtag_id"
              v-model="dataRegion.gtag_id"
              autofocus
              trim
              placeholder="gtag_id"
            />
          </b-form-group>
          <!-- Start of week -->
          <validation-provider
            #default="validationContext"
            name="start_of_week"
            rules="required"
          >
            <b-form-group
              label="Start of week"
              label-for="start_of_week"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="dataRegion.start_of_week"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="weekDaysOption"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="start_of_week"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Region Owner -->
          <b-form-group
            v-if="dataRegion.user"
            label="Region owner"
            label-for="Region Owner"
          >
            <vue-autosuggest
              ref="autocomplete"
              v-model="dataRegion.user.email"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchResults"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import vSelect from "vue-select";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as utils from "@/utils/utils";
import { mapGetters } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    BCard,
    VueAutosuggest,
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isUpdateRegionSidebarActive",
    event: "update:is-update-region-sidebar-active",
  },
  props: {
    isUpdateRegionSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataRegion: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      email,
      alphaNum,
      required,
      isMaintanceOptions: [
        {
          label: "Active",
          value: true,
        },
        {
          label: "Inactive",
          value: false,
        },
      ],
      query: "",
      results: [],
      timeout: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: "autosuggest__input_ajax",
        placeholder: "Input email",
        class: "form-control",
        name: "ajax",
      },
      suggestions: [],
      sectionConfigs: {
        destinations: {
          ulClass: { "a-class-name-here": true },
          // applies to all `<li>` in default sections
          liClass: { "default-row": true },
          limit: 6,
          label: "",
          onSelected: (selected) => {
            this.selected = selected.item;
            this.dataRegion.user.email = selected.item.email;
          },
        },
        hotels: {
          limit: 6,
          label: "Hotels",
          onSelected: (selected) => {
            this.selected = selected.item;
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("app", ["regionPriceOptions"]),
    ...mapGetters("app", ["weekDaysOption"]),
  },
  methods: {
    onChangeTitle() {
      this.dataCategory.slug = utils.toSlug(this.dataCategory.name);
    },
    fetchResults() {
      // const { query } = this
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        // const photosPromise = axios.get(this.photosUrl)
        // const usersPromise = axios.get(this.usersUrl)
        store
          .dispatch("user/fetchUsers", {
            q: this.dataRegion.user.email,
            take: 4,
            page: 1,
            isActive: false,
          })
          .then((response) => {
            this.suggestions = [];
            this.selected = null;
            const users = this.filterResults(
              response.body.data,
              this.dataRegion.user.email,
              "email"
            );
            // const users = response.body.data
            users.length &&
              this.suggestions.push({ name: "destinations", data: users });
          })
          .catch((e) => {
            console.log(e);
          });
      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
          return false;
        })
        .sort();
    },
    renderSuggestion(suggestion) {
      return <span>{suggestion.item.email}</span>;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.email;
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const selected = ref(null);
    const onSubmit = () => {
      const tranformData = {
        name: props.dataRegion.name,
        address: props.dataRegion.address,
        post_code: props.dataRegion.post_code,
        country: props.dataRegion.country,
        is_maintaince: props.dataRegion.is_maintaince,
        add_to_cart: props.dataRegion.add_to_cart,
        is_payment: props.dataRegion.is_payment,
        region_id: props.dataRegion.region_id,
        region_owner: selected.value
          ? selected.value.id
          : props.dataRegion.region_owner,
        tax_indirect: 0,
        tax_local: parseFloat(props.dataRegion.tax_local),
        tax_service: 0,
        start_of_week: props.dataRegion.start_of_week,
        currency: props.dataRegion.currency,
        region_url: props.dataRegion.region_url,
        gtm_id: props.dataRegion.gtm_id,
        facebook_pixel_id: props.dataRegion.facebook_pixel_id,
        gtag_id: props.dataRegion.gtag_id,
      };
      store
        .dispatch("regionConfig/updateRegionConfig", {
          id: props.dataRegion.id,
          regionConfig: tranformData,
        })
        .then(() => {
          emit("refetch-data");
          emit("update:is-update-region-sidebar-active", false);
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const blankCategory = {
      name: "",
      second_name: "",
      second_slug: "",
      slug: "",
    };
    const categoryData = ref(JSON.parse(JSON.stringify(blankCategory)));
    const resetuserData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategory));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      toast,
      categoryData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      selected,
    };
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";

#add-new-category-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
ul {
  list-style-type: none;
}
</style>
