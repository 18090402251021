<template>
  <div>
    <region-list-update
      :is-update-region-sidebar-active.sync="isUpdateRegionSidebarActive"
      :data-region="dataRegion"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                disabled
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewTagsSidebarActive = true"
              >
                <span class="text-nowrap">Add Tags</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refRegionsTable"
          class="position-relative"
          :items="fetchRegions"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-product', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: is add to cart -->
          <template #cell(is_add_to_cart)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.add_to_cart"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('region_update')"
                @change="switchAddToCart(data.item)"
              />
            </span>
          </template>
          <!-- Column: is maintance -->
          <template #cell(isMaintance)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.is_maintaince"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('region_update')"
                @change="switchIsMainTance(data.item)"
              />
            </span>
          </template>
          <!-- Column: payment -->
          <template #cell(isPayment)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.is_payment"
                class="custom-control-primary"
                name="check-button"
                switch
                :disabled="!$can('region_update')"
                @change="switchPayment(data.item)"
              />
            </span>
          </template>
          <template #cell(showPriceLow)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.show_price_low"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="switchShowPriceLow(data.item)"
              />
            </span>
          </template>
          <!-- Column: is maintance -->
          <template #cell(taxService(%))="data">
            <span class="text-capitalize">
              {{ data.item.tax_service }}
            </span>
          </template>
          <template #cell(taxLocal(%))="data">
            <span class="text-capitalize">
              {{ data.item.tax_local }}
            </span>
          </template>
          <template #cell(taxIndirect(%))="data">
            <span class="text-capitalize">
              {{ data.item.tax_indirect }}
            </span>
          </template>
          <template #cell(currency)="data">
            <span class="text-capitalize">
              {{ data.item.currency }}
            </span>
          </template>
          <template #cell(regionOwner)="data">
            <span class="text-capitalize">
              {{ data.item.user ? data.item.user.email : "None" }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('region_update')"
              @click="showModalUpdate(data.item.id)"
            >
              Edit
            </b-link>

            <!-- <b-link
              v-if="$can('region_update')"
              @click="showModalUpdate(data.item.id)"
            >
              Switch
            </b-link> -->
            <b-link
              :href="data.item.region_url"
              target="_blank"
            >
              Preview
            </b-link>
            <b-link
              v-if="$can('tracking_read')"
              @click="showModalTracking(data.item.id)"
            >
              Tracking
            </b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegionConfig"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import RegionListUpdate from '@/components/regionConfig/RegionListUpdate.vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useTagsList from '@/views/regionConfig/region'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrackingStatus from '../modal/TrackingStatus.vue'

export default {
  components: {
    TrackingStatus,
    RegionListUpdate,
    BOverlay,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    showModalTracking(id) {
      this.target = `storeConnection:regionConfig:${id}`
      this.actor = ''
      this.isShowModalTracking = true
    },
    mapConcurancy(regionID) {
      return this.regionPriceOptions.find(
        element => element.value === regionID,
      ).label
    },
    showModalUpdate(id) {
      store.dispatch('regionConfig/getRegionConfig', id).then(response => {
        this.isUpdateRegionSidebarActive = true
        if (response.body.user) {
          this.dataRegion = response.body
        } else {
          this.dataRegion = {
            ...response.body,
            user: {},
          }
        }
        // this.dataRegion = response.body
      })
    },
    switchIsMainTance(regionConfig) {
      const payload = regionConfig
      // eslint-disable-next-line prefer-destructuring
      const id = regionConfig.id
      payload.is_maintaince = !regionConfig.is_maintaince
      store
        .dispatch('regionConfig/updateRegionConfig', {
          id,
          regionConfig: payload,
        })
        .then(() => {
          this.refreshTable()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update maintance success',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update maintance',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    switchPayment(regionConfig) {
      const payload = regionConfig
      // eslint-disable-next-line prefer-destructuring
      const id = regionConfig.id
      payload.is_payment = !regionConfig.is_payment
      this.showTable = true
      store
        .dispatch('regionConfig/updateRegionConfig', {
          id,
          regionConfig: payload,
        })
        .then(() => {
          this.showTable = false
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update payment success',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.showTable = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update payment',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.showTable = false
        })
    },
    switchShowPriceLow(regionConfig) {
      const payload = regionConfig
      // eslint-disable-next-line prefer-destructuring
      const id = regionConfig.id
      payload.show_price_low = !regionConfig.show_price_low
      if (!payload.show_price_low) {
        payload.add_to_cart = false
        payload.is_payment = false
      }
      this.showTable = true
      store
        .dispatch('regionConfig/updateRegionConfig', {
          id,
          regionConfig: payload,
        })
        .then(() => {
          this.showTable = false
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update show price low success',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.showTable = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error show price low payment',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.showTable = false
        })
    },
    switchAddToCart(regionConfig) {
      const payload = regionConfig
      // eslint-disable-next-line prefer-destructuring
      const id = regionConfig.id
      payload.add_to_cart = !regionConfig.add_to_cart
      if (!payload.add_to_cart) {
        payload.is_payment = false
      }
      this.showTable = true
      store
        .dispatch('regionConfig/updateRegionConfig', {
          id,
          regionConfig: payload,
        })
        .then(() => {
          this.showTable = false
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update is add to cart success',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.showTable = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error update is add to cart',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.showTable = false
        })
    },
  },
  setup() {
    const target = ref('')
    const actor = ref('')
    const isShowModalTracking = ref(false)
    const dataRegion = ref({
      user: {
        email: '',
      },
    })
    const isUpdateRegionSidebarActive = ref(false)
    const {
      timeout,
      showTable,
      fetchRegions,
      tableColumns,
      perPage,
      currentPage,
      totalRegionConfig,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRegionsTable,
      refetchData,
    } = useTagsList()
    return {
      timeout,
      target,
      actor,
      isShowModalTracking,
      dataRegion,
      showTable,
      // Sidebar
      isUpdateRegionSidebarActive,
      fetchRegions,
      tableColumns,
      perPage,
      currentPage,
      totalRegionConfig,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRegionsTable,
      refetchData,

      // Filter
      avatarText,

      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
